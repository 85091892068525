const PENDING = 'PENDING'
const PROCESSING = 'PROCESSING'
const COMPLETED = 'COMPLETED'
const CANCELLED = 'CANCELLED'

export default {
  PENDING,
  PROCESSING,
  COMPLETED,
  CANCELLED,
}
