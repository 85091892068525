import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSettlementListConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/finance/page-setups/settlement-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchShopsByMerchantConfig(ctx, { merchantId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/finance/page-setups/merchants/${merchantId}/shops`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPresignedUrl(ctx, { key }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/finance/settlements/presigned-url?key=${key}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSettlements(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/finance/settlements', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSettlement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/finance/settlements/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSettlement(ctx, settlementData) {
      // console.log("settlementData en store: " + JSON.stringify(settlementData));
      // TODO: ver por que no funciona el request sin el JSON.stringify
      return new Promise((resolve, reject) => {
        axios
          .post('/finance/settlements', settlementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    publishSettlement(ctx, settlementData) {
      const url = `/finance/settlements/${settlementData.value.settlementCode}/publish`

      return new Promise((resolve, reject) => {
        axios
          .put(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    unpublishSettlement(ctx, settlementData) {
      const url = `/finance/settlements/${settlementData.value.settlementCode}/unpublish`

      return new Promise((resolve, reject) => {
        axios
          .put(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
